<template>
  <div>
    <b-alert variant="warning" show v-if="!$route.query.access_token">
      <h4 class="alert-heading">
        Please Log In
      </h4>
      <div class="alert-body">
        <span>You need to be logged in to post to Facebook</span>
      </div>
    </b-alert>
    <div class="d-flex justify-content-center mb-1">
      <b-spinner variant="success" label="Spinning" block v-if="loading" style="width: 3rem; height: 3rem"></b-spinner>
    </div>
    <b-card class="blog-edit-wrapper">
      <b-media no-body vertical-align="center">
        <b-media-body>
          <b-card-text>{{ date }} </b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->

      <!-- form -->
      <b-form class="mt-2" @submit.prevent="postImage()">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Content" label-for="blog-content" class="mb-2">
              <b-form-textarea id="textarea-rows" placeholder="Enter Content" rows="8" required
                :state="content.length >= 3" v-model="content" aria-invalid="true" />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="mb-1">Featured Images</h4>
              <b-media no-body vertical-align="center" class="flex-column flex-md-row">
                <b-media-aside>
                  <div class="image-preview-grid">
                    <div v-for="(preview, index) in imagePreviews" :key="index" class="image-preview-item">
                      <b-img :src="preview" height="110" width="170" class="rounded mr-2 mb-1 mb-md-0" />
                    </div>
                  </div>
                </b-media-aside>
                <b-media-body>
                  <small class="text-muted">Required image resolution 800x400, image size 10mb.</small>
                  <b-card-text class="my-50">
                    <b-link id="blog-image-text">
                      {{ imageFiles.length ? `${imageFiles.length} files selected` : "" }}
                    </b-link>
                  </b-card-text>
                  <div class="d-inline-block">
                    <input id="file-upload" type="file" @change="showImagePreview($event)" accept="image/png, image/jpeg"
                      multiple />
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>

          <b-col cols="12" class="my-2">
            <b-form-checkbox v-model="selected" class="custom-control-primary">
              Want to schedule this post?
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" class="mb-2" v-if="selected">
            <b-form-group>
              <h5 class="font-weight-bold">Schedule your post (optional)</h5>
              <flat-pickr v-model="TimeDate" class="form-control" :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                minTime: minDateTime,
                defaultDate: new Date(),
                time_24hr: true,
              }" />
              Note: Scheduled posts need to be shared between 20 minutes and 75 days from
              when you create them.
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" type="submit"
              :disabled="isDisabled" v-if="$route.query.access_token">
              <b-spinner variant="success" label="Spinning" block v-if="loading"></b-spinner>Save Changes
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary"
              :to="{ name: 'marketing-blog-list' }">
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BAlert,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BSpinner,
  BFormFile,
  BFormTextarea,
  BFormCheckbox,
  BLink,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";

export default {
  components: {
    BCard,
    BSpinner,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BAlert,
    BFormTextarea,
    BForm,
    BLink,
    BImg,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: null,
      TimeDate: null,
      bearerToken: localStorage.getItem("accessToken"),
      profileFile: null,
      imageFiles: [],
      imagePreviews: [],
      content: "",
      allowedTags: ["em"],
      input: null,
      isDisabled: false,
      isImageUploading: false,
      blogEdit: {},
      excerpt: "",
      date: new Date(),
      blogFile: null,
      loading: false,
      categoryOption: ["Fashion", "Food", "Gaming", "Quote", "Video"],
      statusOption: ["Published", "Pending", "Draft"],
      facebookToken: null,
      snowOption: {
        theme: "snow",
      },
    };
  },

  mounted() {
    this.facebookToken = this.$route.query.access_token;
    this.loadingFunction();
  },

  methods: {

    minDateTime() {
      let now = new Date();
      now.setMinutes(now.getMinutes() + 15);
      return now;
    },
    showImagePreview(event) {
      this.imageFiles = [...event.target.files];
      this.imagePreviews = [];

      for (let file of this.imageFiles) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.imagePreviews.push(e.target.result);
        };

        reader.readAsDataURL(file);
      }
    },
    confirmText(response) {
      this.$swal({
        title: "Post Created!",
        text: "Here is your post link: " + response,
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Back to Marketing Feed",
        cancelButtonText: "View post",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-info ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$router.push("/marketing/blog/list");
        } else {
          window.open(response, "_blank");
          this.$router.push("/marketing/blog/list");
        }
      });
    },
    uploadImage() {
      this.isImageUploading = true;
      setTimeout(() => {
        console.log(this.imageFile);
        this.isImageUploading = false;
        this.clearImage();
        alert("Image uploaded sucessfully!");
      }, 3000);
    },
    postImage() {
  const url = process.env.VUE_APP_API_URL;
  const endpoint = `${url}marketing/facebook/pagepost`;
  const content = this.content;
  this.loading = true;
  this.isDisabled = true;

  let date = new Date(this.TimeDate).getTime() / 1000;
  let scheduleUnixTimeStamp = date.toString();

  if (!content) {
    alert("Please enter content");
  } else {
    if (scheduleUnixTimeStamp == 0) {
      scheduleUnixTimeStamp = null;
    }
    const fileReadPromises = this.imageFiles.map((file, index) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve({
            name: file.name || `image${index}`,
            extension: file.type.split("/")[1],
            data: reader.result, 
            user: "test",
          });
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(fileReadPromises).then((filesArray) => {
      const requestBody = {
        files: filesArray,
        content: content,
        token: this.facebookToken,
        scheduleUnixTimeStamp: scheduleUnixTimeStamp,
      };

      axios.defaults.headers.common["Authorization"] = "Bearer " + this.bearerToken;
      axios
        .post(endpoint, requestBody)
        .then((response) => {
          this.confirmText(response.data.message);
          this.loading = false;
          this.isDisabled = false;
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }
},

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
.image-preview-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-preview-item {
  width: 170px;
  height: 110px;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 5px;
}
</style>
